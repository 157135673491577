import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {environment} from "../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class CsrfTokenService {
    private csrfTokenUrl = `${environment.apiUrl}/auth/csrf/token`;

    constructor(private http: HttpClient) {
    }

    getCsrfToken(): Observable<string> {
        return this.http.get<{ headerName: string, token: string }>(this.csrfTokenUrl, {withCredentials: true}).pipe(
            tap(response => {
                localStorage.setItem('csrfToken', response.token);
            }),
            map(response => response.token)
        );
    }

    getCsrfTokenFromStorage(): string | null {
        return localStorage.getItem('csrfToken');
    }
}
